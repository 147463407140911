import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import MayabaeMain from "../../components/branding/mayabae/mayabaeMain"
import MayabaeFullwidth from "../../components/branding/mayabae/mayabaeFullwidth"
import MayabaeMoreBranding from "../../components/branding/mayabae/mayabaeMoreBranding"

import brandingFeaturedImage from "../../../static/branding/mayabae/mayabae-featured-image.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/mayabae.scss"

export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="MayaBae: Yoga-Inspired Branding | WowMakers"
            description="Uncover the zen-like harmony in MayaBae's brand identity. Our design philosophy aligns with their yoga-centric approach."
        />
    </>
)


const MayabaeBranding = () => {
    return (
        <Layout>
            <Seo
                bid="branding-mayabae"
                bclass="case-studies-page branding" 
            >
            </Seo>
            <ScrollIndicationHeightZero />
            <MayabaeMain />
            <MayabaeFullwidth />
            <MayabaeMoreBranding />
        </Layout>
    )
}

export default MayabaeBranding
